import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LoginForm } from "../../components/AppointmentSchedulingXcare/AppointmentSchedulingXcare";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import { isLogin } from "../../../../utils/global";
import { Button, Modal } from "react-bootstrap";

export const SignupPage = () => {
  const location = useLocation();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (isLogin("patient_cookie")) {
      history.replace("/");
    }
  }, []);
  return (
    <>
      <Modal show={showDisclaimer} onHide={() => setShowDisclaimer(false)}>
        <Modal.Header closeButton>
          <Modal.Title>General Merchant Disclaimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {" "}
            The merchants represented are not sponsors of the rewards or
            otherwise affiliated with this company. The logos and other
            identifying marks attached are trademarks of and owned by each
            represented company and/or its affiliates. Please visit each
            company's website for additional terms and conditions
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDisclaimer(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <HeaderWhite location={location}></HeaderWhite>
      <section>
        <div className="main-grid-sec" style={{ marginTop: 80 }}>
          <div className="container">
            <h2 className="like-h1 text-center fw-bold">
              Xcare Rewards Program
            </h2>
            <h5 className="fst-normal my-3 text-center">
              Welcome to the Xcare Rewards Program, where you can earn rewards
              for spreading smiles!
            </h5>
            <div className="row">
              <div className="col-md-7 order-md-1 order-2">
                {/* <img width={'100%'} src={LeftImg}></img> */}
                <div className="py-4">
                  <h2 className="h2 fw-bold">Program Benefits:</h2>
                  <ul className="custom-list my-5 text-start">
                    <li>
                      <span className="fw-bold">Earn Points:</span>
                      Book and manage your appointments on Xcare and engage with
                      your providers to earn points.
                    </li>
                    <li>
                      <span className="fw-bold">Refer Your Friends:</span>
                      Share your love for Xcare Points with your friends and
                      family by inviting them to join our program. You can refer
                      as many people as you'd like!
                    </li>
                    <li>
                      <span className="fw-bold">Redeem Points:</span>
                      Use your points to redeem a variety of gift cards,
                      discounts on treatments or products, and many other
                      exclusive perks.
                    </li>
                    <li>
                      <span className="fw-bold">Track Your Progress:</span>
                      Access your points balance and rewards history through the
                      Xcare platform.
                    </li>
                    <li>
                      <span className="fw-bold">Elevated Tiers:</span>
                      Get bonus points, specific free promotional treatments,
                      enjoy exclusive discounts, and experience the perks of
                      being a loyal member of Xcare Rewards.
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="h2 fw-bold text-center">
                    Simplifying loyalty
                  </h3>
                  <h3 className="h2 fw-bold text-center">
                    Enrol, Engage & Earn.
                  </h3>
                  <ul className="custom-list my-5 text-start">
                    <li>
                      <span className="fw-bold">Enrol effortlessly:</span>
                      Sign up takes mere seconds, simply create a rewards
                      account to join.
                    </li>
                    <li>
                      <span className="fw-bold">Engage seamlessly:</span>
                      Manage your appointments via Xcare and claim points.
                    </li>
                    <li>
                      <span className="fw-bold">Earn Rewards:</span>
                      Accumulate points and redeem rewards.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 order-md-2 order-1">
                <div className="practice-sidebar">
                  <LoginForm
                    noBackToHome
                    onLoginSuccess={() => {
                      window.location.href = "/";
                    }}
                    noGuestLogin
                    goToSignup
                  ></LoginForm>
                </div>
              </div>
            </div>
            <div className="py-4">
              <h2 className="like-h1 text-center fw-bold">How It Works</h2>
              <h4 className="fst-normal my-3 text-center">
                Our Rewards Program is simple and Rewarding
              </h4>
              <ul className="custom-list my-5 text-start">
                <li>
                  <span className="fw-bold">Enrollment Points:</span>
                  When you join our program, you'll receive enrollment points as
                  a welcome bonus points.
                </li>
                <li>
                  <span className="fw-bold">Earn Points:</span>
                  At Xcare, we value your engagement and loyalty, which is why
                  we reward you for choosing convenient ways to book and manage
                  your appointments. You can earn points for various actions,
                  including:
                  <ul className="my-2">
                    <li>
                      Booking and managing new and existing appointments through
                      Xcare
                    </li>
                    <li>Referring friends and family to Xcare</li>
                    <li>Writing reviews or providing feedback</li>
                  </ul>
                </li>
              </ul>
              <h6 className="text-center">
                Your participation not only makes your patient experience
                smoother but also earns you points that can be redeemed for
                exciting rewards.
              </h6>
            </div>
            <div className="row py-4">
              <div className="col-md-7 order-md-1 order-2">
                <h2 className="h2 fw-bold">Reedem Rewards</h2>
                <p>
                  Once you've accumulated 5000 points, you can redeem them for a
                  variety of rewards, including:
                </p>
                <ul className="my-3 custom-list">
                  <li>Gift Cards</li>
                  <li>Discounts on products or services</li>
                  <li>Exclusive offers</li>
                  <li>Charitable donations</li>
                </ul>
              </div>
              <div className="col-md-5 order-md-2 order-1">
                <img
                  src={require("../../assets/images/reward-1.png")}
                  width={"100%"}
                ></img>
                <img
                  src={require("../../assets/images/reward-2.png")}
                  width={"100%"}
                ></img>
                <img
                  src={require("../../assets/images/reward-3.png")}
                  width={"100%"}
                ></img>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-link ms-auto mr-3"
                style={{ color: "red", marginRight: "5px" }}
                onClick={() => {
                  setShowDisclaimer(true);
                }}
              >
                {" "}
                General Disclaimer{" "}
              </button>
            </div>
            <div className="text-center py-4">
              <h2 className="like-h1 fw-bold">Have Questions?</h2>
              <p>
                If you have any questions about the Xcare Rewards Referral
                Program or need assistance with your referral link,
              </p>
              <p>
                please contact us at{" "}
                <a href="mailto:support@xcarepro.com">support@xcarepro.com</a>
              </p>
              <p>
                We're here to help you make the most of your rewards program!
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
