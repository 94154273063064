const DEV_HOST = ["dev.xcarepro.com"];
const STAGING_HOST = [
  "staging.xcarepro.com",
  "stagingus.xcarepro.com",
  "localhost"
];
const PROD_HOST = ["xcarepro.com", "www.xcarepro.com", "us.xcarepro.com"];
const hostname = window.location.hostname;
let configs: any = {};
if (
  DEV_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Development environment
  const SOURCE = "https://xcarepro.com/";
  // const SOURCE = "https://dev.xcarepro.com/";
  configs = {
    SOURCE_URL: SOURCE,
    API_URL: SOURCE + "api_v2/",
    IMAGE_URL: SOURCE + "uploads/",
    DEFAULT_IMAGE_URL_DOCTOR:
      SOURCE + "uploads/doctor/default_doctor_photo.jpg",
    DEFAULT_IMAGE_URL_CLINIC: SOURCE + "uploads/clinic/default_clinic_logo.jpg",
    GOOGLE_API_KEY: "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8",
    COOKIE_DOMAIN: ".dev.xcarepro.com",
    PATIENT_COOKIE_DOMAIN: ".patient.dev.xcarepro.com",
    PATIENT_URL: "http://patient.dev.xcarepro.com/",
    CLINIC_URL: "http://app.dev.xcarepro.com/",
    FRONT_URL: "https://dev.xcarepro.com",
    PATIENT_APPOINTMENT_URL: "http://patient.dev.xcarepro.com/appointments",
    CHAT_COLLECTION_NAME: "chat_dev", //chat for staging and chat_live for live
    UTILITY_URL: "https://utility.dev.xcarepro.com/",
    PRACTICE_URL: "https://xcare.app/special-offer/list-as-a-practice/",
    PROVIDER_URL: "https://provider.dev.xcarepro.com/",
    FRESHDESK_URL: "https://xcarepro.freshdesk.com/support/home",
  };
} else if (
  STAGING_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Development environment
  let SOURCE = "https://staging.xcarepro.com/";
  if (hostname === "stagingus.xcarepro.com") {
    SOURCE = "https://stagingus.xcarepro.com/";
  }
  configs = {
    SOURCE_URL: SOURCE,
    API_URL: SOURCE + "api_v2/",
    IMAGE_URL: SOURCE + "uploads/",
    DEFAULT_IMAGE_URL_DOCTOR:
      SOURCE + "uploads/doctor/default_doctor_photo.jpg",
    DEFAULT_IMAGE_URL_CLINIC: SOURCE + "uploads/clinic/default_clinic_logo.jpg",
    GOOGLE_API_KEY: "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8",
    COOKIE_DOMAIN:
      hostname === "stagingus.xcarepro.com"
        ? ".stagingus.xcarepro.com"
        : ".staging.xcarepro.com",
    PATIENT_COOKIE_DOMAIN:
      hostname === "stagingus.xcarepro.com"
        ? ".patient.stagingus.xcarepro.com"
        : ".patient.staging.xcarepro.com",
    PATIENT_URL:
      hostname === "stagingus.xcarepro.com"
        ? "http://patient.stagingus.xcarepro.com/"
        : "http://patient.staging.xcarepro.com/",
    CLINIC_URL:
      hostname === "stagingus.xcarepro.com"
        ? "http://app.stagingus.xcarepro.com/"
        : "http://app.staging.xcarepro.com/",
    FRONT_URL:
      hostname === "stagingus.xcarepro.com"
        ? "https://stagingus.xcarepro.com"
        : "https://staging.xcarepro.com",
    PATIENT_APPOINTMENT_URL:
      hostname === "stagingus.xcarepro.com"
        ? "http://patient.stagingus.xcarepro.com/appointments"
        : "http://patient.staging.xcarepro.com/appointments",
    CHAT_COLLECTION_NAME:
      hostname === "stagingus.xcarepro.com" ? "us_chat" : "chat", //chat for staging and chat_live for live
    UTILITY_URL:
      window.location.hostname === "app.stagingus.xcarepro.com"
        ? "https://utility.stagingus.xcarepro.com/"
        : "https://utility.staging.xcarepro.com/",
    PRACTICE_URL: "stagingus.xcarepro.com"
      ? "https://xcare.app/special-offer/list-as-a-practice/"
      : "https://xcare.app/special-offer/list-as-a-practice/",
    PROVIDER_URL: "stagingus.xcarepro.com"
      ? "https://provider.staging.xcarepro.com/"
      : "https://provider.staging.xcarepro.com/",
    FRESHDESK_URL: "https://xcarepro.freshdesk.com/support/home",
  };
} else if (
  PROD_HOST.findIndex((item) => {
    return item === hostname;
  }) !== -1
) {
  // Production environment
  let SOURCE = "https://www.xcarepro.com/";
  if (hostname === "us.xcarepro.com") {
    SOURCE = "https://us.xcarepro.com/";
  }
  configs = {
    SOURCE_URL: SOURCE,
    API_URL: SOURCE + "api_v2/",
    IMAGE_URL: SOURCE + "uploads/",
    DEFAULT_IMAGE_URL_DOCTOR:
      SOURCE + "uploads/doctor/default_doctor_photo.jpg",
    DEFAULT_IMAGE_URL_CLINIC: SOURCE + "uploads/clinic/default_clinic_logo.jpg",
    GOOGLE_API_KEY: "AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8",
    COOKIE_DOMAIN:
      hostname === "us.xcarepro.com" ? ".us.xcarepro.com" : ".xcarepro.com",
    PATIENT_COOKIE_DOMAIN:
      hostname === "us.xcarepro.com"
        ? ".patient.us.xcarepro.com"
        : ".patient.xcarepro.com",
    PATIENT_URL:
      hostname === "us.xcarepro.com"
        ? "http://patient.us.xcarepro.com/"
        : "http://patient.xcarepro.com/",
    CLINIC_URL:
      hostname === "us.xcarepro.com"
        ? "http://app.us.xcarepro.com/"
        : "http://app.xcarepro.com/",
    FRONT_URL:
      hostname === "us.xcarepro.com"
        ? "https://us.xcarepro.com"
        : "https://xcarepro.com",
    PATIENT_APPOINTMENT_URL:
      hostname === "us.xcarepro.com"
        ? "http://patient.us.xcarepro.com/appointments"
        : "http://patient.xcarepro.com/appointments",
    CHAT_COLLECTION_NAME:
      hostname === "us.xcarepro.com" ? "us_chat_live" : "chat_live", //chat for staging and chat_live for live
    UTILITY_URL:
      window.location.hostname === "app.us.xcarepro.com"
        ? "https://utility.us.xcarepro.com/"
        : "https://utility.xcarepro.com/",
    PRACTICE_URL:
      hostname === "us.xcarepro.com"
        ? "https://xcare.us.app/special-offer/list-as-a-practice/"
        : "https://xcare.app/special-offer/list-as-a-practice/",
    PROVIDER_URL:
      hostname === "us.xcarepro.com"
        ? "https://provider.us.xcarepro.com/"
        : "https://provider.xcarepro.com/",
    FRESHDESK_URL: "https://xcarepro.freshdesk.com/support/home",
  };
}
export default configs;
